import { Shipper } from './Shipper'

export const MILEAGE_PROVIDERS = [
  'PCM_CURRENT',
  'PCM18',
  'PCM19',
  'PCM20',
  'PCM21',
  'PCM22',
  'PCM23',
  'PCM24',
  'PCM25',
  'PCM26',
  'PCM27',
  'PCM28',
  'PCM29',
  'PCM30',
  'PCM31',
  'PCM32',
  'PCM33',
  'PCM34',
  'gmaps',
] as const

export type MileageProvider = typeof MILEAGE_PROVIDERS

export interface ShipperEntity extends Shipper {
  fuel_rate?: number
  has_quote_response_integration?: boolean
  tms_url?: string
  mileage_provider: MileageProvider
}
